export function authcheck(): boolean {
  return sessionStorage.getItem("_token") != null;
}

export function canAccess(level: string): boolean {
  return true; // untill we have the token in place, just let everything fly..
  switch (level.toLowerCase()) {
    case "admin":
      return !!sessionStorage.getItem("admin_token");
    default:
      return true;
  }
}
