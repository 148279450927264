//import { canAccess } from "@/helpers/auth";
import Vue from "vue";
import Vuex from "vuex";
import { api } from "../network/api";
import router from "../router";

const url = process.env.VUE_APP_API_ENDPOINT;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    moduleLibrary: [],
    ownedComponents: [],
    moduleFiles: [],
    navigation: {
      dashboards: [],
      pages: ["/admin"],
    },
    org: [],
    user: {
      email: "",
    },
    coreloaded: false,
  },
  mutations: {
    FETCHED_ORGS(state, val) {
      if (sessionStorage.getItem("activeOrg") == null) {
        sessionStorage.setItem("activeOrg", val[0]._id);
      }

      return val;
    },
    CHANGED_ORG(state, val) {
      sessionStorage.setItem("activeOrg", val);
    },
    UPDATE_GRID(val) {
      return val;
    },
    UPDATE_DASHBOARD() {
      return true;
    },
    ORG_UPDATED(val) {
      return val;
    },
    UPDATE_FILTER(state, val) {
      return val;
    },
    retrievedDashboards(state, val) {
      state.navigation.dashboards = val;
    },
    FORCE_MOVE_USER_TO_DASHBOARD(val) {
      return val;
    },
    component_list_loaded(state, val) {
      state.moduleLibrary = val;
    },
    ENDPOINT_UPDATED(state, val) {
      return val;
    },
    GOT_COMPONENTS_FOR_DASHBOARD(val) {
      return val;
    },
    SEND_NOTIF(val) {
      return val;
    },
  },
  getters: {
    getOrganizationList: (state) => {
      return state?.org ?? [];
    },

    getDashboardComponents: (state) => async (config) => {
      let components = [];
      if (config.length) {
        config.forEach((module) => {
          if (state.ownedComponents.includes(module)) {
            let item = state.moduleLibrary.filter((obj) => {
              return obj._id == module;
            });
            if (item.length) {
              components.push(item[0]);
            }
          }
        });
      }
      return components;
    },
    getCompomnentsById: (state) => (arrayOfComponentIds) => {
      let components = [];
      arrayOfComponentIds.forEach((module) => {
        let item = state.moduleLibrary.filter((obj) => {
          return obj._id == module;
        });
        if (item.length) {
          components.push(item[0]);
        }
      });

      return components;
    },
  },
  actions: {
    // TODO: remove this
    // eslint-disable-next-line
    setFilter(context, val) {
      context.commit("UPDATE_FILTER", { filter: val });
    },
    changeOrg(context, val) {
      console.log("changeORg");
      api
        .post(`${url}/user/refresh/token`, {
          org_id: val,
          userId: context.state.user._id,
          did: sessionStorage.getItem("_device"),
        })
        .then((res) => {
          sessionStorage.setItem("_token", res.data);
          context.commit("CHANGED_ORG", val);
          context.dispatch("getOrgs").then(() => {
            context.dispatch("getAvailableComponents");
            context.dispatch("getAvailableDashboards").then(() => {
              router.push({
                path:
                  "/dashboard/" + context.state.navigation.dashboards[0]._id,
              });
            });
          });
        })
        .catch((err) => {
          context.commit("SEND_NOTIF", {
            headline: "Error: " + err.response.status,
            text: err.response.data,
            type: "ERROR",
          });
        });
    },
    setUser(context, val) {
      context.state.user = val;
    },
    setModuleFiles(context, val) {
      context.state.moduleFiles = val;
    },
    refreshDashboard(context) {
      context.commit("UPDATE_DASHBOARD");
    },
    updateComponent(context, val) {
      if (val.id == 0) {
        delete val.newComponent._id;
        api
          .post(`${url}/dashboard/components/`, val.newComponent)
          .then(() => {
            context.dispatch("getComponentList");

            context.commit("SEND_NOTIF", {
              headline: val.newComponent.data.title,
              text: "Component updated",
              type: "SUCCESS",
            });
          })
          .catch((err) => {
            context.commit("SEND_NOTIF", {
              headline: "Error",
              text: err,
              type: "ERROR",
            });
          });
      } else {
        api
          .put(`${url}/dashboard/components/${val.id}`, val.newComponent)
          .then(() => {
            context.dispatch("getComponentList");
            context.commit("SEND_NOTIF", {
              headline: val.newComponent.data.title,
              text: "Component updated",
              type: "SUCCESS",
            });
          })
          .catch((err) => {
            context.commit("SEND_NOTIF", {
              headline: "Error",
              text: err,
              type: "ERROR",
            });
          });
      }
    },
    async updateOrg(context, val) {
      if (val.id == 0) {
        delete val._id;
        await api
          .post(`${url}/organizations`, {
            title: val.orgData.name,
            settings: { dashboard_component_ids: val.orgData.ownedComponents },
          })
          .then((res) => {
            context.commit("ORG_UPDATED", res.data);
            context.commit("SEND_NOTIF", {
              headline: val.orgData.name,
              text: "has been created",
              type: "SUCCESS",
            });
          })
          .catch((err) => {
            context.commit("SEND_NOTIF", {
              headline: "Error",
              text: err,
              type: "ERROR",
            });
          });
      } else {
        await api
          .patch(`${url}/organizations/${val.id}`, {
            title: val.orgData.name,
            settings: { dashboard_component_ids: val.orgData.ownedComponents },
          })
          .then((res) => {
            context.commit("ORG_UPDATED", res.data);
            context.commit("SEND_NOTIF", {
              headline: val.orgData.name,
              text: "has been updated",
              type: "SUCCESS",
            });
          })
          .catch((err) => {
            context.commit("SEND_NOTIF", {
              headline: "Error",
              text: err,
              type: "ERROR",
            });
          });
      }
      context.dispatch("getOrgs", true);
    },
    updateEndpoint(context, val) {
      if (val.id == 0) {
        delete val.newEndpoint._id;
        api
          .post(`${url}/dashboard/endpoints`, val.newEndpoint)
          .then((res) => {
            context.commit("ENDPOINT_UPDATED", res.data);
            context.commit("SEND_NOTIF", {
              headline: val.newEndpoint.meta.title,
              text: "has been created",
              type: "SUCCESS",
            });
          })
          .catch((err) => {
            context.commit("SEND_NOTIF", {
              headline: "Error",
              text: err,
              type: "ERROR",
            });
          });
      } else {
        api
          .patch(`${url}/dashboard/endpoints/${val.id}`, val.newEndpoint)
          .then((res) => {
            context.commit("ENDPOINT_UPDATED", res.data);
            context.commit("SEND_NOTIF", {
              headline: val.newEndpoint.meta.title,
              text: "has been updated",
              type: "SUCCESS",
            });
          })
          .catch((err) => {
            context.commit("SEND_NOTIF", {
              headline: "Error",
              text: err,
              type: "ERROR",
            });
          });
      }
    },
    sendNotif(context, val) {
      context.commit("SEND_NOTIF", val);
    },
    createDashboard(context, val) {
      api
        .post(`${url}/dashboards`, { title: val })
        .then((res) => {
          context.dispatch("getOrgs", true);
          context.dispatch("getAvailableDashboards").then(() => {
            context.commit("FORCE_MOVE_USER_TO_DASHBOARD", res.data);
          });
        })
        .catch((err) => {
          context.commit("SEND_NOTIF", {
            headline: "Error: " + err.response.status,
            text: err.response.data,
            type: "ERROR",
          });
        });
    },
    deleteDashboard(context, val) {
      api
        .delete(`${url}/dashboards/${val.removed}`)
        .then(() => {
          context.dispatch("getAvailableDashboards").then((_dashboards) => {
            if (val.removed == val.current) {
              context.commit(
                "FORCE_MOVE_USER_TO_DASHBOARD",
                context.state.navigation.dashboards[0]._id
              );
            }
          });
        })
        .catch((err) => {
          context.commit("SEND_NOTIF", {
            headline: "Error: " + err.response.status,
            text: err.response.data,
            type: "ERROR",
          });
        });
    },
    updateComponentLayout(context, val) {
      api
        .patch(`${url}/dashboards/${val.dashboardId}`, {
          components: val.layout,
        })
        .catch((err) => {
          context.commit("SEND_NOTIF", {
            headline: "Error: " + err.response.status,
            text: err.response.data,
            type: "ERROR",
          });
        });
    },
    editComponent(context, val) {
      api
        .patch(`${url}/dashboards/${val.dashboardId}`, {
          components: val.component,
        })
        .then(() => {
          context.dispatch("getAvailableDashboards").then(() => {
            context.dispatch("refreshDashboard");
          });
        })
        .catch((err) => {
          context.commit("SEND_NOTIF", {
            headline: "Error: " + err.response.status,
            text: err.response.data,
            type: "ERROR",
          });
        });
    },
    async getDashBoardItemsMapAction(context, val) {
      const res = await api.get(`${url}/dashboards/${val}`).catch((err) => {
        context.commit("SEND_NOTIF", {
          headline: "Error",
          text: err,
          type: "ERROR",
        });
      });
      //eslint-disable-next-line @typescript-eslint/no-unused-vars
      context.commit("GOT_COMPONENTS_FOR_DASHBOARD", res.data);
    },
    async getDashBoardItemsMap(context, val) {
      return new Promise(async (resolve, _reject) => {
        const res = await api.get(`${url}/dashboards/${val}`).catch((err) => {
          context.commit("SEND_NOTIF", {
            headline: "Error: " + err.response.status,
            text: err.response.data,
            type: "ERROR",
          });
        });
        resolve(res.data);
      });
    },
    getComponentList(context) {
      api
        .get(`${url}/dashboard/components/library`)
        .then((res) => {
          context.commit("component_list_loaded", res.data);
        })
        .catch((err) => {
          context.commit("SEND_NOTIF", {
            headline: "Error: " + err.response.status,
            text: err.response.data,
            type: "ERROR",
          });
        });
    },
    async getOrgs(context, forceUpdate = false) {
      return new Promise(async (resolve, _reject) => {
        if (!context.state.org.length || forceUpdate) {
          const res = await api.get(`${url}/organizations`).catch((err) => {
            context.commit("SEND_NOTIF", {
              headline: "Error: " + err.response.status,
              text: err.response.data,
              type: "ERROR",
            });
          });
          context.state.org = res.data;
          context.commit("FETCHED_ORGS", res.data);
          resolve(res.data);
        } else {
          context.commit("FETCHED_ORGS", context.state.org);
          resolve(context.state.org);
        }
      });
    },
    async getAvailableDashboards(context) {
      return new Promise(async (resolve, _reject) => {
        const res = await api.get(`${url}/dashboards`).catch((err) => {
          context.commit("SEND_NOTIF", {
            headline: "Error: " + err.response.status,
            text: err.response.data,
            type: "ERROR",
          });
        });
        context.commit("retrievedDashboards", res.data);
        resolve(res.data);
      });
    },
    async getAvailableComponents({ state }) {
      return new Promise(async (resolve, _reject) => {
        const res = await api
          .get(`${url}/dashboard/components/`)
          .catch((err) => {
            context.commit("SEND_NOTIF", {
              headline: "Error",
              text: err,
              type: "ERROR",
            });
          });
        state.ownedComponents = res.data;
        resolve();
      });
    },
    updateGrid(context, val) {
      context.commit("UPDATE_GRID", val);
    },

    fetchProperties(_context) {
      console.log("fetchProperties");
      // NOTE: currently just used as an event
    },
  },
  modules: {},
});
