import Vue from "vue";
import VueRouter from "vue-router";
import { authcheck, canAccess } from "@/helpers/auth";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/dashboard/:dashboardid",
    name: "Dashboard",
    // no lazy load here.. it is the first view, we need it before the user wants to navigate there.
    component: HomeView,
  },
  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "AdminView" */ "@/views/AdminView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "loginView" */ "@/views/loginView.vue"),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () =>
      import(/* webpackChunkName: "loginView" */ "@/views/SignupView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/AboutView.vue"),
  },
  {
    path: "/onboard",
    name: "Onboarding",
    component: () => import("@/components/properties/AddProperty.vue"),
  },
  {
    path: "/properties",
    name: "Properties",
    component: () => import("@/views/PropertiesView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, _from, next) => {
  if (
    (!authcheck() && !["Login", "Signup", "Onboarding"].includes(to.name)) ||
    !canAccess(to.name)
  ) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
