import { BifrostAPI } from "./bifrost";

const API = new BifrostAPI();

const wrapper = {
  get: async function (url: string, external = false, headers = {}, queue = true) {
    const u = new URL(external || url.startsWith("http") ? url : process.env.VUE_APP_API_ENDPOINT + url);
    return API.request("GET", u.href.split("?")[0], u.searchParams, headers, queue);
  },
  put: async function (url: string, body = {}) {
    return API.request("PUT", url.startsWith("http") ? url : process.env.VUE_APP_API_ENDPOINT + url, body);
  },
  patch: async function (url: string, body = {}) {
    return API.request("PATCH", url.startsWith("http") ? url : process.env.VUE_APP_API_ENDPOINT + url, body);
  },
  post: async function (url: string, body: object, external = false, headers = {}) {
    const u = new URL(external || url.startsWith("http") ? url : process.env.VUE_APP_API_ENDPOINT + url);
    return API.request("POST", u.href, body, headers);
  },
  delete: async function (url: string, body: object, external = false, headers = {}) {
    const u = new URL(external || url.startsWith("http") ? url : process.env.VUE_APP_API_ENDPOINT + url);
    return API.request("DELETE", u.href, body, headers);
  },
};

export const api = {
  install(Vue) {
    // This adds the $getPermissions method to all instances
    Vue.prototype.$api = wrapper;
  },
  ...wrapper,
};
