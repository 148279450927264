<template>
  <div id="app">
    <router-view v-if="!loading" :key="$route.fullPath" />
  </div>
</template>

<script>
import { TYPE } from "vue-toastification";
export default {
  data() {
    return {
      loading: true,
      demount: null,
    };
  },
   beforeDestroy() {
    this.demount();
    console.log('prøv lige at log APP')
  },
  mounted() {
    if (sessionStorage.getItem("_token")) {
      this.$store.dispatch("getOrgs").then(() => {
        this.$store.dispatch("getComponentList");
      });
    } else {
      this.loading = false;
    }
    this.demount = this.$store.subscribe(async (mutation, _state) => {
      switch (mutation.type) {
        case "UPDATE_GRID":
          this.$delete(this.items, mutation.payload.module);
          break;
        case "component_list_loaded":
          this.loading = false;
          break;
        case "FETCHED_ORGS":
          if (sessionStorage.getItem("_token")) {
            this.$store.dispatch("getAvailableDashboards");
          }
          break;
        case "SEND_NOTIF":
          if (mutation.payload.headline.length) {
            this.$toast(
              <p>
                <span class="font-bold">{mutation.payload.headline}</span> {mutation.payload.text}
              </p>,
              { type: TYPE[mutation.payload.type] }
            );
          } else {
            this.$toast(
              <p>
               {mutation.payload.text}
              </p>,
              { type: TYPE[mutation.payload.type] }
            );
          }

          break;
        default:
          break;
      }
    });
  },
};
</script>
