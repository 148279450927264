
import axios, { AxiosRequestConfig } from 'axios';
import getBrowserFingerprint from 'get-browser-fingerprint';

//eslint-disable-next-line
type deffAxiosCfg = AxiosRequestConfig<any> & { resolver: Function, rejector: Function};
export class BifrostAPI {
    private deferredRequests: { [group: string] : deffAxiosCfg[] } = {};
    private deferredTimers: { [group: string]: number | ReturnType<typeof setTimeout> } = {};

    /* private controller = new AbortController(); */

    private deviceId: string | null = null;
    private getAuthToken(): string | null {
        return sessionStorage.getItem('_token');;
    }
    private getDeviceId(){
        if(this.deviceId){
            return this.deviceId;
        }
        this.deviceId = sessionStorage.getItem('_device');
        if(!this.deviceId){
            const webglSupport = (): boolean => { 
                try {
                    const canvas = document.createElement('canvas'); 
                    return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
                } catch(e) {
                    return false;
                }
            };
            this.deviceId = getBrowserFingerprint({ enableWebgl: webglSupport() });
            sessionStorage.setItem('_device', `${this.deviceId}`);
        }

        return this.deviceId;
    }
    private async generateHeaders(payload: object, _h?: {[key: string]: string}){
        //console.log({ payload })
        const headers: {[key: string]: string} = _h || {};

        headers['x-authorization'] = this?.getAuthToken() ?? 'unauthenticated';
        headers['x-requested'] = `${this.getDeviceId()}:${new Date().getTime()}`;
        headers['x-mac'] = [...new Uint8Array(await crypto.subtle.digest("SHA-256",new TextEncoder().encode(JSON.stringify({payload: payload ? payload : {} ,headers}))))].map(x=>x.toString(16).padStart(2,'0')).join("");
        
        return headers;
    }
    private async drainDeferredRequest(group: string){

        //console.log('drain', group)

        // await first request to ensure cache is hot for following request.
        if(this.deferredRequests[group] && this.deferredRequests[group].length){
            //console.log('await first request (prime cache)');
            const dr = this.deferredRequests[group].pop();
            try {
                /* try {
                    this.controller.abort()
                } catch(_) {

                } */
                //eslint-disable-next-line
                const req = await axios(dr as AxiosRequestConfig<any>);
                dr?.resolver(req);
            } catch(err) {
                dr?.rejector(err);
            }
        }

        // execute the rest of deffred request without awaiting the response.
        //console.log("excute rest of group request", this.deferredRequests[group].length);
        while(this.deferredRequests[group] && this.deferredRequests[group].length){
            const prom = this.deferredRequests[group].pop();
            //eslint-disable-next-line
            axios(prom as AxiosRequestConfig<any>)
                .then(res => prom?.resolver(res))
                .catch(err => prom?.rejector(err));
        }
    }
    private async deferredRequest(request: deffAxiosCfg): Promise<unknown>{
        // group request based on first part of url.
        // await first response pr group, and execute rest of group request.
        const group = request.url?.split('/')[3] || '' as string;

        // create a promise we can resolve later on.
        const prom = new Promise(async (resolve, reject) => {
            request.resolver = resolve;
            request.rejector = reject;
        });
        if(!this.deferredRequests[group]){
            this.deferredRequests[group] = [];
        }
        this.deferredRequests[group].push(request);

        // delay the execution of request.
        if(!this.deferredTimers[group]){
            this.deferredTimers[group] = setTimeout(() => this.drainDeferredRequest(group), 50);
        } else {
            clearTimeout(this.deferredTimers[group]);
            this.deferredTimers[group] = setTimeout(() => this.drainDeferredRequest(group), 50);
        }

        // return the promise that we will deal with later.
        return prom;
    }
    async request(method: string, endpoint: string, payload: object, headers?: {[key: string]: string}, deferred = true): Promise<unknown>{
        
        let _p = {}
        if(payload) {
            if("entries" in payload){
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                for(const [key, value] of payload.entries()){
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    _p[key] = value
                }
            } else {
                _p = payload
            }
            
        }

        const request: deffAxiosCfg = {
            url: endpoint,
            /* signal: this.controller.signal, */
            method: method,
             // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            headers: await this.generateHeaders(_p, headers),
            //eslint-disable-next-line
            resolver: () => {}, // this will be overwritten later
            //eslint-disable-next-line
            rejector: () => {}, // this will be overwritten later
        }
        switch(method){
            case "GET":
            case "DELETE":
                request.params = payload;
                // server side; req.query -> mac
                break;
            case "POST":
            case "PUT":
            case "PATCH":
                request.data = payload;
                // server side; req.body -> mac
                break;
            default:
                break;
        }

        //console.log(request)

        if(deferred){
            return this.deferredRequest(request);
        } else {
            /* try {
                this.controller.abort()
            } catch(_) {

            } */
            return await axios(request);
        }
    }

}
