<template>
  <div class="linegraph bg-white flex items-start grey-dark shadow-md">
    <p class="text-lg pl-4 pt-4 font-bold">{{ series[0].name }}</p>
    <apexchart
      ref="linechart"
      v-if="!loading"
      class="w-full"
      height="250"
      type="area"
      :options="options"
      :series="series"
    ></apexchart>
    <div class="flex w-full" v-if="loading">
      <vue-skeleton-loader
        class="ml-4 mt-1"
        type="rect"
        :width="50"
        :height="240"
        animation="fade"
      />
      <vue-skeleton-loader
        class="mx-3 mt-1"
        type="rect"
        :width="'93%'"
        :height="240"
        animation="fade"
      />
    </div>
  </div>
</template>

<script>
import { generateDefaultFilter } from "../../../helpers/default_filters";
import VueApexCharts from "vue-apexcharts";
import VueSkeletonLoader from "skeleton-loader-vue";

export default {
  name: "linegraph",
  props: ["data", "_id"],
  components: {
    apexchart: VueApexCharts,
    VueSkeletonLoader,
  },
  data() {
    return {
      value: "",
      width: 25,
      loading: true,
      demount: null,
      options: {
        chart: {
          id: "vuechart-example",
          type: "area",
          height: 250,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
          foreColor: "#0069d9",
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        labels: [],
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return new Intl.NumberFormat().format(val);
            },
          },
        },
        xaxis: {
          tooltip: false,
          type: "datetime",
          labels: {
            datetimeUTC: true,
            format: "MMMM dd, yyyy",
          },
          categories: [],
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex }) => {
            const months = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ];
            return (
              '<div class="arrow_box p-2 border-2 rounded border-blue shadow-xl bold text-md">' +
              "<span> " +
              months[
                new Date(this.options.labels[dataPointIndex]).getUTCMonth()
              ] +
              " " +
              new Date(this.options.labels[dataPointIndex]).getUTCDate() +
              "<br> </span>" +
              '<span class="font-bold">' +
              series[seriesIndex][dataPointIndex] +
              "</span>" +
              "</div>"
            );
          },
          enabled: true,
          style: {
            fontSize: 24,
          },
        },
        noData: {
          text: "Loading...",
        },
      },

      series: [
        {
          name: this.data.title,
          data: [],
        },
      ],
    };
  },
  beforeDestroy() {
    this.demount();
  },
  methods: {
    getData(filter = { filter: generateDefaultFilter() }) {
      this.loading = true;
      this.$api
        .get("/dashboard/endpoints/" + this.data.endpoint)
        .then((res) => {
          this.$api.post(res.data.url, filter).then((res) => {
            this.series[0].data = res.data.historical.series[0].data;
            this.options.xaxis.categories = res.data.historical.categories;
            this.options.labels = res.data.historical.categories;
            this.loading = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getData();
    this.demount = this.$store.subscribe((mutation, _state) => {
      switch (mutation.type) {
        case "UPDATE_FILTER":
          this.getData(mutation.payload);
          break;
        default:
          break;
      }
    });
  },
};
</script>

<style lang="scss">
.linegraph {
  flex-direction: column;
  overflow: hidden;
  position: relative;
  border-radius: 0.3em;
  min-height: 300px;
}
</style>
