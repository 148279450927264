<template>
  <div>
    <div class="kpi-card flex items-center grey-dark shadow-md">
      <div class="flex p-4 w-full">
        <div
          v-if="data.icon"
          class="kpi-card__icon w-1/5 justify-center text-center text-2xl"
        >
          <div
            :style="{
              background: hexToRgba(data.icon.color, 0.2),
            }"
            class="kpi-card__icon-container flex items-center justify-center w-12 h-12 rounded-full"
          >
            <font-awesome-icon
              :style="{ color: hexToRgba(data.icon.color, 1) }"
              :icon="data.icon.code"
            />
          </div>
        </div>
        <div class="kpi-card__content w-full flex flex-col pl-4">
          <span
            v-if="!loading"
            class="kpi-card__value leading-7 text-2xl w-full min-h-[28px]"
            >{{ data.prefix.length ? data.prefix : ""
            }}{{ Intl.NumberFormat().format(parseInt(value, 10)) }}
          </span>
          <span
            v-if="loading"
            class="kpi-card__value leading-7 rounded-xl text-2xl w-full min-h-[28px]"
          >
            <vue-skeleton-loader
              type="rect"
              :width="100"
              :height="28"
              animation="fade"
            />
          </span>

          <span class="kpi-card__text"
            >{{ data.title }}
            {{ data.username ? "of " + data.username : "" }}</span
          >
        </div>
        <div v-if="data.platform" class="kpi-card__identity">
          <font-awesome-icon
            class="text-3xl"
            :icon="'fa-brands fa-' + data.platform"
          />
        </div>
      </div>
      <div class="w-full chartContainer">
        <apexchart
          class="chart relative top-0 left-0"
          v-if="graph && !loading"
          height="70"
          width="102%"
          type="area"
          :options="chartOptions"
          :series="graphData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import hexToRgba from "hex-to-rgba";
import VueApexCharts from "vue-apexcharts";
import VueSkeletonLoader from "skeleton-loader-vue";
import { generateDefaultFilter } from "../../../helpers/default_filters";

export default {
  name: "KPI",

  // TODO: type this
  props: ["data", "_id"],

  components: {
    apexchart: VueApexCharts,
    VueSkeletonLoader,
  },

  data() {
    return {
      value: "",
      graphData: null,
      width: 25,
      graph: false,
      loading: true,
      demount: null,
      chartOptions: {
        chart: {
          type: "area",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 300,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        colors: [this.data.icon ? this.data.icon.color : "#0069d9"],
        yaxis: {
          show: true,
          logarithmic: false,
          forceNiceScale: true,
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          grid: {
            padding: {
              left: 0,
              right: 0,
            },
          },
        },

        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
            bottom: 0,
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
          style: {
            fontSize: 24,
            fontFamily: "Saira",
          },
          x: {
            format: "dd/MM/yy",
          },
        },
        noData: {
          text: "Loading...",
        },
      },
    };
  },
  beforeDestroy() {
    this.demount();
    console.log("prøv lige at log kpi");
  },
  methods: {
    hexToRgba(col, alpha) {
      return hexToRgba(col, alpha);
    },
    getData(filter = { filter: generateDefaultFilter() }) {
      this.loading = true;
      this.$api
        .get("/dashboard/endpoints/" + this.data.endpoint)
        .then((res) => {
          this.$api.post(res.data.url, filter).then((res) => {
            this.value = res.data.value;
            if (res.data.historical) {
              this.chartOptions.xaxis.categories =
                res.data.historical.categories;
              this.graphData = res.data.historical.series;
              this.graph = true;
            }
            this.loading = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getData();
    this.demount = this.$store.subscribe((mutation, _state) => {
      switch (mutation.type) {
        case "UPDATE_FILTER":
          this.getData(mutation.payload);
          break;
        default:
          break;
      }
    });
  },
};
</script>

<style lang="scss">
.grey-dark {
  background: #fff;
  color: theme("colors.blue");
}
.kpi-card {
  flex-direction: column;
  overflow: hidden;
  position: relative;
  border-radius: 0.3em;
  min-width: 231px;
  min-height: 80px;
  &__value {
    font-weight: bolder;
  }
  &__text {
    font-size: 70%;
    padding-left: 0.2em;
  }
  &__icon {
  }
}
.chartContainer {
  height: 64px;
  margin-top: -64px;
}
.chart {
  padding-top: 9px;
  margin-left: -10px;
}
</style>
