
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import vuuri from "vuuri";
import "./assets/css/main.css";
import VModal from "vue-js-modal";
import axios from "axios";
import VueAxios from "vue-axios";

import { library } from "@fortawesome/fontawesome-svg-core";

import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(fas, fab);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import vSelect from "vue-select";

Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

import { api } from "./network/api";
Vue.use(api);

import modules from "./components/modules/components.js";
Object.entries(modules).forEach(([name, component]) =>
  Vue.component(name, component)
);
// TODO: make these loops into the one
const files: string[] = [];
Object.entries(modules).forEach(([filename, _component]) => {
  files.push(filename);
});
store.dispatch("setModuleFiles", files);
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  // You can set your default options here
  position: "bottom-right",
  draggable: false,
});

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
Vue.use(VModal, {
  adaptive: true,
});

Vue.use(VueAxios, axios);
Vue.config.productionTip = false;

Vue.component("vuuri", vuuri);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
