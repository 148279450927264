<template>
  <div
    class="piechart bg-white flex flex-col h-full items-start grey-dark shadow-md overflow-hidden"
  >
    <p class="text-lg w-full pl-4 pt-4 font-bold mb-5">{{ data.title }}</p>
    <apexchart
      ref="piechart"
      v-if="!loading"
      class="w-full h-full flex"
      :height="500"
      :options="options"
      :series="series"
      type="donut"
    ></apexchart
    ><!-- 
    <div class="flex w-full" v-if="loading">
      <vue-skeleton-loader
        class="ml-4 mt-1"
        type="rect"
        :width="50"
        :height="240"
        animation="fade"
      />
    </div> -->
  </div>
</template>

<script>
import { generateDefaultFilter } from "../../../helpers/default_filters";
import VueApexCharts from "vue-apexcharts";
import VueSkeletonLoader from "skeleton-loader-vue";

export default {
  name: "piechart",
  props: ["data", "_id"],
  components: {
    apexchart: VueApexCharts,
    VueSkeletonLoader,
  },
  data() {
    return {
      value: "",
      width: 25,
      loading: true,
      demount: null,
      options: {
        chart: {
          id: "vuechart-example",
          type: "donut",
          zoom: {
            enabled: false,
          },
          foreColor: "#0069d9",
        },
        legend: {
          formatter: function (seriesName, opts) {
            return `<p style="background: ${
              opts.w.globals.colors[opts.seriesIndex]
            }" class="text-[white] rounded py-1 px-2">${seriesName}</p>`;
          },
          horizontalAlign: "left",
          onItemHover: {
            highlightDataSeries: true,
          },
          show: true,
          position: "bottom",
          markers: {
            width: 0,
            height: 0,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 0,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 2,
            vertical: -4,
          },
        },
        plotOptions: {
          pie: {
            customScale: 1,
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: [],
        tooltip: {
          custom: ({ series, seriesIndex }) => {
            return (
              '<div class="arrow_box p-2 border-2 rounded border-blue shadow-xl bold text-md">' +
              "<span class='uppercase'> " +
              this.options.labels[seriesIndex] +
              "<br> </span>" +
              '<span class="font-bold">' +
              series[seriesIndex] +
              "</span>" +
              "</div>"
            );
          },
          enabled: false,
          style: {
            fontSize: 24,
            fontBold: true,
          },
        },
        noData: {
          text: "Loading...",
        },
      },

      series: [],
    };
  },
  beforeDestroy() {
    this.demount();
  },
  methods: {
    getData(filter = { filter: generateDefaultFilter() }) {
      this.loading = true;
      this.$api
        .get("/dashboard/endpoints/" + this.data.endpoint)
        .then((res) => {
          this.$api.post(res.data.url, filter).then((res) => {
            console.log(res);
            this.loading = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    /*  this.getData(); */

    let datastuffhardcode = {
      languageStats: [
        { type: "english", val: 2016 },
        { type: "danish", val: 132 },
        { type: "german", val: 95 },
        { type: "spanish; castilian", val: 59 },
        { type: "czech", val: 57 },
        { type: "romanian, moldavian, moldovan", val: 53 },
        { type: "french", val: 47 },
        { type: "swedish", val: 44 },
        { type: "dutch", val: 41 },
        { type: "hungarian", val: 40 },
        { type: "italian", val: 38 },
        { type: "greek, modern", val: 36 },
        { type: "albanian", val: 36 },
        { type: "polish", val: 34 },
        { type: "bulgarian", val: 26 },
        { type: "serbian", val: 24 },
        { type: "portuguese", val: 24 },
        { type: "norwegian", val: 22 },
        { type: "arabic", val: 19 },
        { type: "finnish", val: 19 },
        { type: "turkish", val: 18 },
        { type: "slovak", val: 17 },
        { type: "croatian", val: 15 },
        { type: "lithuanian", val: 14 },
        { type: "latvian", val: 9 },
        { type: "armenian", val: 9 },
        { type: "macedonian", val: 9 },
        { type: "estonian", val: 6 },
        { type: "norwegian bokmål", val: 6 },
        { type: "tagalog", val: 5 },
        { type: "russian", val: 5 },
        { type: "slovene", val: 4 },
        { type: "romansh", val: 4 },
        { type: "georgian", val: 3 },
        { type: "urdu", val: 3 },
        { type: "bosnian", val: 3 },
        { type: "persian", val: 2 },
        { type: "assamese", val: 2 },
        { type: "norwegian nynorsk", val: 2 },
        { type: "hindi", val: 2 },
        { type: "kurdish", val: 2 },
        { type: "ukrainian", val: 2 },
        { type: "bengali", val: 2 },
        { type: "malay", val: 1 },
        { type: "icelandic", val: 1 },
        { type: "north ndebele", val: 1 },
        { type: "esperanto", val: 1 },
        { type: "afrikaans", val: 1 },
        { type: "catalan; valencian", val: 1 },
        { type: "yoruba", val: 1 },
        { type: "thai", val: 1 },
        { type: "uzbek", val: 1 },
        { type: "japanese", val: 1 },
        { type: "amharic", val: 1 },
        { type: "cree", val: 1 },
        { type: "scottish gaelic; gaelic", val: 1 },
        { type: "venda", val: 1 },
        { type: "indonesian", val: 1 },
        { type: "afar", val: 1 },
        { type: "avestan", val: 1 },
        { type: "tajik", val: 1 },
      ],
    };
    this.series = datastuffhardcode.languageStats.map((x) => x.val);
    this.options.labels = datastuffhardcode.languageStats.map((x) => x.type);
    setTimeout(() => {
      this.loading = false;
    }, 500);

    this.demount = this.$store.subscribe((mutation, _state) => {
      switch (mutation.type) {
        case "UPDATE_FILTER":
          this.getData(mutation.payload);
          break;
        default:
          break;
      }
    });
  },
};
</script>

<style lang="scss">
.linegraph {
  flex-direction: column;
  overflow: hidden;
  position: relative;
  border-radius: 0.3em;
  min-height: 300px;
}
</style>
