<template>
  <div class="postList rounded-md mb-5">
    <div
      class="postList__header dragHandle bg-blue shadow-md p-4 rounded mb-3 flex text-[white] font-bold uppercase"
    >
      <div class="w-20">
        <!-- <img class="h-full rounded-full" :src="post.post.media"> -->
      </div>
      <div class="w-[35%] flex px-2">
        <font-awesome-icon class="mr-2" icon="fa-solid fa-photo-film" /> content
      </div>
      <div class="w-[18%] px-2 flex justify-center">Impressions</div>
      <div class="w-[18%] px-2 flex justify-center">Reach</div>
      <div class="w-[18%] px-2 flex justify-center">Video views</div>
      <div class="w-[18%] px-2 flex justify-center">EMV</div>
      <div class="w-[18%] px-2 flex justify-center">EMV</div>
      <div class="postList__filter">
        <div class="postList__handle">
          <!--           <select>
            <option>Test</option>
          </select> -->
        </div>
      </div>
    </div>

    <!-- Search bar -->
    <div
      class="h-[64px] shadow-md mb-4 bg-[white] rounded-md flex items-center"
    >
      <input
        v-model="searchList"
        v-on:keyup.enter="search"
        class="w-[400px] ml-6 h-[65%] bg-[#0069d90f] border-[1px] pl-3 rounded border-[#dddddd] outline-none"
        type="text"
        placeholder="Search for a post"
      />
      <button
        @click="search"
        class="px-4 py-2 border-blue border-2 rounded-md m-3 font-bold uppercase text-blue"
      >
        Search
      </button>
    </div>
    <ul v-if="loading">
      <li>
        <div
          v-for="index in 5"
          :key="index"
          class="flex w-full rounded mb-2 shadow-md items-center"
        >
          <vue-skeleton-loader
            type="rect"
            :width="8000"
            :height="80"
            animation="fade"
          />
        </div>
      </li>
    </ul>
    <transition-group
      name="staggered-fade"
      tag="ul"
      v-if="!loading"
      class="postList__posts flex flex-col max-h-[670px] overflow-x-hidden overflow-y-scroll"
      :css="false"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:leave="leave"
    >
      <li
        class="flex w-full p-3 bg-[white] rounded mb-2 shadow-md items-center"
        v-for="(post, index) in value"
        :key="post._id"
        :data-index="index"
      >
        <div v-if="!loading && value.length" class="w-20">
          <img
            v-if="post && post.post && post.post.media_url"
            :src="post.post.media_url"
            alt=""
            class="rounded h-14 w-14 !bg-center !bg-cover object-cover"
            @error="post.post.media_url = null"
          />
          <div
            class="rounded h-14 w-14 text-center flex place-items-center place-content-center bg-[#f7f7f7]"
            v-else
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="27"
              viewBox="0 0 34 27"
              class="text-[#5c5c5c]"
            >
              <path
                fill="currentColor"
                d="M16.37 2.492h-2.662c-6.183 0-11.19 4.931-11.19 11.008 0 6.077 5.007 11.008 11.19 11.008h13.796a1.246 1.246 0 0 1 0 2.492H13.708C6.138 27 0 20.956 0 13.5S6.137 0 13.708 0h4.354a1.246 1.246 0 1 1 0 2.492H16.37zm-2.098 6.439v.01h-.564c-2.557 0-4.63 2.04-4.63 4.559 0 2.518 2.073 4.56 4.63 4.56h6.115v.01h1.598a1.246 1.246 0 1 1 0 2.492h-4.116v-.01h-3.597c-3.946 0-7.149-3.155-7.149-7.052s3.203-7.052 7.149-7.052h.345c.051-.006.103-.01.156-.01h2.584a1.246 1.246 0 0 1 0 2.493h-2.521zm7.532-2.493h.036a1.246 1.246 0 1 1 0 2.493h-.036a1.246 1.246 0 1 1 0-2.493zM26.43 18.07h2.125a1.246 1.246 0 1 1 0 2.493H26.43a1.246 1.246 0 0 1 0-2.493zm6.07 6.439h.036a1.246 1.246 0 0 1 0 2.492H32.5a1.246 1.246 0 0 1 0-2.492z"
              />
            </svg>
          </div>

        </div>
        <div class="w-[35%] flex flex-col px-2">
          <div class="flex">
            <span class="icon h-full w-5 min-w-[1.25rem] mt-1 mr-2" v-html="socialMediaIcon(post.platform).icon"></span>
            <a class="text-black font-semibold" :href="post && post.post && post.post.link ? post.post.link : '#'" target="_blank">{{ post.post.content }}</a>
          </div>

          <p
            class="text-[#9e9e9e] ml-7 inline capitalize text-xs"
            :title="`${post.handle} @${post.platform}`"
          ><span :style="{background: getKindColor(post.property.kind)}" :class="['px-1 py-1 text-[white] rounded-sm text-xs']">{{post.property.kind}}</span>
            {{ post.media_type }} by <span class="font-bold text-xs"> {{ post.property.title }}</span>
            <span class="text-xs"> {{ new Date(post.created ) | formatDate }}</span>
          </p>
        </div>
        <div class="text-black w-[18%] px-2 font-bold flex justify-center">
          {{ post.media_metrics.impressions | zero }}
        </div>
        <div class="text-black w-[18%] px-2 font-bold flex justify-center">
          {{ post.media_metrics.reach | zero }}
        </div>
        <div class="text-black w-[18%] px-2 font-bold flex justify-center">
          {{ post.media_metrics.video_views | zero }}
        </div>
        <div class="text-black w-[18%] px-2 font-bold flex justify-center">
          {{typeof post.media_value[org_id] == 'undefined' || parseInt(post.media_value[org_id]) == 0 || post.media_value[org_id] == "" ? '' : '$'}}{{ post.media_value[org_id] | zero }}
        </div>
         <div class="text-black w-[18%] px-2 font-bold flex justify-center">
          <button>Details</button>
        </div>
      </li>
      <li v-if="!loading" class="flex justify-center" :key="246246246246">
        <button
          @click="loadMore"
          class="p-4 border-blue border-2 rounded-md m-3 font-bold uppercase text-blue"
        >
          Load more
        </button>
      </li>
    </transition-group>
  </div>
</template>

<script>
import Velocity from "velocity-animate";
import VueSkeletonLoader from "skeleton-loader-vue";
import { generateDefaultFilter } from "../../helpers/default_filters";
import socialMediaIcon from '../../helpers/social_media'
export default {
  props: ["data", "_id"],
  name: "PostList",
  components: {
    VueSkeletonLoader,
  },
  data() {
    return {
      value: [],
      width: 25,
      height: 900,
      loading: true,
      searchList: "",
      busy: false,
      cachedFilter: { filter: generateDefaultFilter() },
      offset: 0,
      demount: null,
    };
  },
      beforeDestroy() {
    this.demount();
    console.log('prøv lige at log postlist')
  },
  mounted() {
     this.demount = this.$store.subscribe((mutation, _state) => {
      switch (mutation.type) {
        case "UPDATE_FILTER":
          this.cachedFilter = JSON.parse(JSON.stringify(mutation.payload));
          if (this.searchList.length) {
            this.cachedFilter.filter.contentFilter.push(this.searchList);
          } else {
            this.cachedFilter = JSON.parse(JSON.stringify(mutation.payload));
          }
          this.getData(this.cachedFilter);
          break;
        default:
          break;
      }
    });
    this.getData();
  },
  methods: {
    socialMediaIcon(platform) {
      return socialMediaIcon(platform)
    },
    getData(filter = this.cachedFilter, loadmore = false) {
      this.loading = true;
      this.$api
        .get("/dashboard/endpoints/" + this.data.endpoint)
        .then((res) => {
          this.$api.post(res.data.url, filter).then((res) => {
            if (this.value.length > 0 && loadmore) {
              this.value.push(...res.data);
            } else {
              this.value = res.data;
            }

            this.loading = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadMore() {
      this.loading = true;
      this.cachedFilter["pagination"] = {
        offset: this.value.length,
        limit: 10,
      };
      this.getData(this.cachedFilter, true);
    },
    search() {
      if (this.searchList.length) {
        this.loading = true;
        if (this.cachedFilter.filter.hasOwnProperty("contentFilter")) {
          this.cachedFilter.filter["contentFilter"].push(this.searchList);
        } else {
          this.$set(this.cachedFilter.filter, "contentFilter", []);
          this.cachedFilter.filter.contentFilter.push(this.searchList);
        }
      } else {
        this.cachedFilter.filter.contentFilter.forEach((string, index) => {
          if (!string.includes("@") && !string.includes("#")) {
            this.cachedFilter.filter.contentFilter.splice(index, 1);
          }
        });
      }

      this.getData(this.cachedFilter);
    },
    beforeEnter: function (el) {
      el.style.opacity = 0;
    },
    enter: function (el, done) {
      var delay = el.dataset.index * 100;
      setTimeout(function () {
        Velocity(el, { opacity: 1 }, { complete: done });
      }, delay);
    },
    leave: function (el, done) {
      var delay = el.dataset.index * 100;
      setTimeout(function () {
        Velocity(el, { opacity: 0 }, { complete: done });
      }, delay);
    },
    getKindColor(kind) {
      switch(kind) {
        case 'member':
          return '#0093ff'
          break;
        case 'team':
          return '#00e7ba'
            break;
        case 'brand':
          return '#92F489'
            break;
        case 'campaign':
          return '#955ab0'
            break;
        case 'content-creator':
          return '#ACC7FD'
            break;
        case 'event':
          return '#404756'
            break;
        default:
          break;
      }
    }
  },
  computed: {
    org_id: () => {
      return sessionStorage.getItem("activeOrg");
    },
  },

  filters: {
    zero(val) {
      //lol
      return (typeof val == 'undefined' || parseInt(val) == 0 || val == "") ? "-" : Intl.NumberFormat().format(parseInt(parseInt(val), 10));
    },
    /**
     *
     * @param {Date} date
     */
    formatDate(date) {
      return `${date.toTimeString().split(" ")[0]} ${date.toDateString()}`;
    },
  },
};
</script>

<style lang="scss">
.componentPreview {
  position: relative;
  .postList {
    position: absolute;
    transform: scale(0.5);
    top: -744px;
  }
}
.icon {
  svg {
    width:21px;
    height:21px;
  }
}
</style>
