import kpi from "../modules/cards/kpi.vue";
import ranking from "../modules/cards/ranking.vue";
import postList from "../modules/postList.vue";
import LatestPosts from "../modules/cards/LatestPosts.vue";
import linegraph from "../modules/cards/linegraph.vue";
import piechart from "../modules/cards/piechart.vue";
export default {
  kpi: kpi,
  ranking: ranking,
  LatestPosts: LatestPosts,
  postList: postList,
  linegraph: linegraph,
  piechart: piechart,
};
