export function generateDefaultFilter(options = {}) {
  const aMonthAgo = new Date();
  aMonthAgo.setMonth(aMonthAgo.getMonth() -1)
  const _defaults = {
    dateRange: {
      from: aMonthAgo,
    },
  };

  return Object.assign(_defaults, options);
}