<template>
  <div class="latestPosts">
    <article class="leaderboard">
      <card>
        <template #header>
          {{ data.title }}
        </template>
        <template #content>
          <div class="leaderboard__profiles">
            <article
              v-for="(player, index) in value"
              :key="index"
              class="leaderboard__profile"
            >
              <img
                src="https://randomuser.me/api/portraits/men/32.jpg"
                alt="Mark Zuckerberg"
                class="leaderboard__picture"
              />
              <span class="leaderboard__name text-blue"
                >{{ player.name }}
                <blockquote class="text-[#c2c2c2]">
                  <p lang="en" dir="ltr">
                    I wonder if we can give some of these cards away 👀
                  </p>
                </blockquote></span
              >
            </article>
          </div>
        </template>
      </card>
    </article>
  </div>
</template>

<script>
export default {
  components: {
    card: () => import("@/components/layout/CardContainer.vue"),
  },
  props: ["data", "_id"],
  name: "LatestPosts",
  data() {
    return {
      value: "",
    };
  },
  mounted() {
    this.$store.dispatch("getComponentData", this._id).then((res) => {
      this.value = res.list;
    });
  },
};
</script>

<style lang="scss" scoped>
.latestPosts {
  max-width: 480px;
}
.leaderboard {
  &__profiles {
    padding: 15px 15px 20px;
    display: grid;
    row-gap: 8px;
  }
  &__profile {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;
    padding: 10px 30px 10px 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
    cursor: pointer;
    transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98),
      box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
    background-color: #fff;
    &:hover {
      transform: scale(1.01);
      box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);
    }
  }
  &__picture {
    max-width: 100%;
    width: 60px;
    border-radius: 50%;
    box-shadow: 0 0 0 10px #ebeef3, 0 0 0 22px #f3f4f6;
  }
  &__name {
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.64px;
    margin-left: 12px;
  }
  &__value {
    color: #35d8ac;
    font-weight: 700;
    font-size: 12px;
    text-align: right;
    & > span {
      opacity: 0.8;
      font-weight: 600;
      font-size: 13px;
      margin-left: 3px;
    }
  }
}
</style>
