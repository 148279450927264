<template>
  <div
    v-if="$store.state.moduleLibrary.length && $store.state.org.id != ''"
    class="dashboard"
  >
    <baselayout>
      <template #content>
        <dashboard :key="$route.params.dashboardid" />
      </template>
    </baselayout>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {
    baselayout: () => import("@/components/layout/base.vue"),
    dashboard: () => import("@/components/layout/dashboard.vue"),
  },
};
</script>
